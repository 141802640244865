// UnderConstruction.js
import React from 'react';
import './UnderConstruction.css';
import logo from '../src/1.jpg';

const UnderConstruction = () => {
  return (
    <div className="under-construction-container">
      <div className="message-container">
      <img src={logo} alt="logo" className="logo"/>
        <h1>Under Construction</h1>
        <p>We are working hard to bring you an amazing website. Stay tuned!</p>
      </div>
    </div>
  );
};

export default UnderConstruction;
